<template>
    <template v-if="capturedError">
        <slot v-if="$slots.error" name="error"></slot>

        <div v-else-if="type === 'card'" class="card text-danger mx-auto my-auto">
            <div class="card-body">
                <h5 class="card-title">
                    <i class="bi bi-exclamation-triangle-fill me-1"></i>
                    {{ $t('Render Error') }}
                </h5>
                <p class="card-text">{{ $t(uiMessage) }}</p>
                <button v-if="!noRerender" class="btn btn-sm btn-link text-danger" @click="rerender">
                    {{ $t('Try to re-render') }}
                </button>
            </div>
        </div>

        <span v-else class="text-danger" :title="$t(uiTitleMessage)" @click="rerender">
            <i class="bi bi-exclamation-triangle-fill me-1"></i>
            {{$t(uiMessage)}}
        </span>
    </template>

    <slot v-else></slot>
</template>

<script setup lang="ts">
import useErrorCapture from './composables.ErrorCapture.ts';

const props = withDefaults(defineProps<{
    type?: 'card'|'span',
    noRerender?: boolean
    consoleMessage?: string,
    uiMessage?: string
    uiTitleMessage?: string,
}>(),{
    type: 'card',
    consoleMessage: 'Error captured by ErrorBoundry',
    uiMessage: 'Render Error',
    // @ts-ignore
    uiTitleMessage: raw => raw.uiMessage,
});

function rerender() {
    if (props.noRerender) { return; }
    capturedError.value = null;
}

const [capturedError] = useErrorCapture({
    consoleMessagee: props.consoleMessage,
});

</script>